import React from 'react'
import { Container } from 'react-bootstrap'
import './PrivacyPolicy.css'

function TermsCondition_User() {
    return (
        <>
            <section className='privacy_section'>
                <Container>
                    <div className='privacy_main'>
                        <div className='title_div mb-4'>
                            <h2 className='semi_title'>TERMS & CONDITIONS</h2>
                        </div>
                        <div className='mb-5 pb-3'>
                            <p className='sub_title'>TERMS AND CONDITIONS FOR USERS</p>
                            <p className='text '>This document is an electronic record in terms of information technology act, 2000 and rules thereunder as applicable & the provisions pertaining to electronic records in various statutes as amended by the information technology act, 2000.</p>
                        </div>
                        <div>
                            <p className='sub_title'>A. GENERAL TERMS</p>
                            <p className='text ms-5'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type & scrambled it to make a type specimen book. it has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. it was popularised in the 1960s with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page- maker including versions of lorem ipsum.</p>

                            <p className='sub_title'>I. SERVICES</p>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>a.</p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum is simply dummy text of the printing</p>
                            </div>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>b. </p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum is simply dummy text of the printing</p>
                            </div>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>c. </p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum is simply dummy text of the printing</p>
                            </div>
                            <div className='d-flex ms-5 mb-4'>
                                <p className='text mb-1'>d. </p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>


                            <p className='sub_title'>II. YOUR REPRESENTATIONS, WARRANTIES AND UNDERTAKINGS</p>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>a.</p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>b. </p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum is simply dummy text of the printing</p>
                            </div>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>c. </p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum is simply dummy text of the printing</p>
                            </div>
                            <div className='d-flex ms-5 mb-4'>
                                <p className='text mb-1'>d. </p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum is simply dummy text of the printing</p>
                            </div>


                            <p className='sub_title'>III. OPERATION OF PLATFORM</p>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>a.</p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>b. </p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum is simply dummy text of the printing</p>
                            </div>
                            <div className='d-flex ms-5'>
                                <p className='text mb-1'>c. </p>
                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry.</p>

                            </div>
                            <div className='d-flex ms-5 mb-4'>
                                <p className='text mb-1'>d. </p>

                                <p className='text mb-1'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum is simply dummy text of the printing</p>
                            </div>

                            <div className='mb-5'>
                                <p className='sub_title'>B. SPECIAL TERMS</p>
                                <p className='text  ms-5'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type & scrambled it to make a type specimen book. it has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. it was popularised in the 1960s with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page- maker including versions of lorem ipsum.</p>
                            </div>
                            
                        </div>
                    </div>
                </Container>
                <div className='privacy_overlay'></div>
            </section>
        </>
    )
}

export default TermsCondition_User