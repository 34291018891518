import React from 'react'
import './OurVision.css'
import { Col, Container, Row } from 'react-bootstrap'
function OurVision() {
  return (
    <>
    <section className='ourvision_section'>
        <Container>
            <div className='ourvision_main'>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6}  className='mb-3'>
                    <div className='ourcardd_div'>
                        <div className='d-flex '>
                        <img src={process.env.PUBLIC_URL + "/assets/images/Aboutus/sqqqrr.png"} className="sqrimg" />
                        
                        <p className='sub_title'>OUR VISION</p>
                        </div>
                        <p className='text'>We envision becoming the top transportation partner for all your travel needs, offering unparalleled service & satisfaction. Our goal is to set new benchmarks for quality in the cab service industry.</p>

                    </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6}  className='mb-3'>
                    <div className='ourcardd_div'>
                    <div className='d-flex'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/Aboutus/sqqqrr.png"} className="sqrimg" />
                    <p className='sub_title'>OUR MISSION</p>
                    </div>
                    <p className='text'>To provide safe, reliable, and efficient transportation solutions that exceed customer expectations. We are committed to delivering top-notch service with every ride.</p>
                    </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>
    </>
  )
}

export default OurVision