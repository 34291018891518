import React from 'react'
import './WhyChoose.css'
import { Col, Container, Row } from 'react-bootstrap'
function WhyChoose() {
    const data = [

        {
            name: "With rexitaxi, you benefit from customers who trust the Reximeter for accurate fare calculations. No need to seek out customers actively—just ensure your vehicle is marked with the rexitaxi logo or Reximeter symbol. Keep the full fare as we don’t take any commission.",
            title: "For Drivers",
            image: process.env.PUBLIC_URL + " /assets/images/homepage/profile.png",

        },
        {
            name: "Enjoy transparent, consistent pricing with the Reximeter. Whether you book via the app or board directly, you’ll always pay a fair rate based on distance traveled. The Reximeter makes pricing straightforward and predictable.",
            title: "For Customers",
            image: process.env.PUBLIC_URL + "/assets/images/homepage/customer.png",

        },

        {
            name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            title: "RAPID CITY TRANSFER",
            image: process.env.PUBLIC_URL + " /assets/images/homepage/Objects.png",

        },
        {
            name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            title: "SAFETY GUARANTEE",
            image: process.env.PUBLIC_URL + "/assets/images/homepage/sheild.png",

        },
        // {
        //     name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        //     title: "PRO CLEARED DRIVERS",
        //     image: process.env.PUBLIC_URL + "/assets/images/homepage/car.png  ",

        // },
    ]
    return (
        <>
            <section className='whychoose_section'>
                <Container>
                    <div className='whychoose_main_div'>
                        <div className='title_div'>
                            <h2 className='title text-center'> <span className='whychooose'>Why Choose </span><img src={process.env.PUBLIC_URL + "/assets/images/logos/rexilogo.png"} className="titleimg" /></h2>
                        </div>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={10} sm={11} className='mx-auto'>
                                <div className="cardddiv">
                                    <Row>
                                        {data.map((item, index) => (
                                            <Col xxl={3} xl={3} lg={3} md={6} sm={6} className='mb-4 mx-auto'>
                                                <div className='botttt'>
                                                    <div className='craddmain'>
                                                        <p className='choosetext text-center'>{item.title}</p>
                                                        <p className='choosubtext text-center mb-0'>{item.name}</p>
                                                        <div className='topdivv'>
                                                            <img src={item.image} className=" topdivv_img" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default WhyChoose