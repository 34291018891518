import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { faEnvelope, faHeadphonesSimple, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
const Footer = () => {
  return (
    <>
      <section className="footer_section">
        <Container>
          <div className="footer_divv">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4} sm={12} className="mx-auto ">
                <div className="footerlogodiv">
                  <div className="footimg_div mb-3">
                    <img src={process.env.PUBLIC_URL + "/assets/images/logos/rexilogo.png"} className="footimg" />
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4} sm={6} className="mx-auto">
                <div className="footerlinksdiv">
                  <p className="titletext pb-md-2">Quick Links</p>

                  <Link to="/aboutus"> <p className="text">About Us</p></Link>
                  <Link to="/privacypolicy"> <p className="text">Privacy Policy</p> </Link>
                  <Link to="/terms&conditions-rider"> <p className="text">Terms & Conditions (Riders)</p> </Link>
                  <Link to="/terms&conditions-user"> <p className="text">Terms & Conditions (Users)</p> </Link>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4} sm={6} className="mx-auto">
                <div className="footerlinksdiv">
                  <p className="titletext pb-md-2">Contact Us</p>
                  <div className="address_div">
                    <div className="mt-1 me-2 ">


                      <div className="footicn_div">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </div>
                    </div>
                    <p className="text">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Pune - 411038</p>
                  </div>

                  <div className="address_div">
                    <div className="mt-1 me-2 ">
                      <div className="footicn_div">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>
                    </div>
                    <p className="text">+91-639-401-7085</p>
                  </div>
                  <div className="address_div">
                    <div className="mt-1 me-2 ">
                      <div className="footicn_div ">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                    </div>
                    <p className="text">support@rexitaxi.com</p>
                  </div>



                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4} sm={6} className="mx-md-auto">
                <div className="footerlogodiv">
                  <p className="titletext pb-md-2">Follow Us </p>
                  <div className="d-flex ">


                    <div className="me-3">
                    <Link to="https://www.facebook.com/" target="blank"> <div className="brandicndiv">
                        <FontAwesomeIcon icon={faFacebookF} />
                      </div></Link> 
                    </div>
                    <div className="me-3">
                    <Link to="https://in.linkedin.com/" target="blank"> <div className="brandicndiv">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </div></Link> 
                    </div>
                    <div className="me-3">
                    <Link to="https://x.com/" target="blank"> <div className="brandicndiv">
                        <FontAwesomeIcon icon={faTwitter} />
                      </div></Link> 
                    </div>
                    <div className="me-3">
                    <Link to="https://www.youtube.com/" target="blank"> <div className="brandicndiv">
                        <FontAwesomeIcon icon={faYoutube} />
                      </div></Link> 
                    </div>
                  </div>

                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </section>



    </>
  );
};

export default Footer;
