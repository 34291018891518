import React from 'react'
import './HowWeWork.css'
import { Col, Container, Row } from 'react-bootstrap'
function HowWeWork() {

    const data = [
        {
            name: "Easy Bookings",
            text: "Book a cab with ease in just a few simple steps. Fast, reliable, and convenient bookings at your fingertips!",
            image: process.env.PUBLIC_URL + "/assets/images/homepage/circle1.png",
        },
        {
            name: "Fastest Pickups",
            text: "Experience the fastest pickups for your rental cab. Get on the road quickly and efficiently with us!",
            image: process.env.PUBLIC_URL + "/assets/images/homepage/circle2.png",
        },
        {
            name: "Quick Rides",
            text: "Get quick rides with our rental cabs for your convenience. Fast, efficient, & reliable transportation when you need it!",
            image: process.env.PUBLIC_URL + "/assets/images/homepage/circle1.png",
        },
        {
            name: "Cleared Drivers",
            text: "All our rental cab drivers are thoroughly vetted and cleared. Trustworthy, professional service for a safe and smooth ride",
            image: process.env.PUBLIC_URL + "/assets/images/homepage/circle3.png",
        },
    ]
    return (
        <>
            <section className='howwork_section'>
                <Container>
                    <div className='howwork_main'>
                        <h2 className='title text-center mb-4'>How We Work</h2>
                        <div className='desktopdiv'>
                            <div className='howimg_div'>
                                <img src={process.env.PUBLIC_URL + "/assets/images/homepage/howweworkk.png"} className="howwweimg" />
                            </div>
                            <div className='rowdiv'>
                            <Row>
                                    {data.map((item, index) => (
                                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} className='mb-4 mx-auto'>
                                            <div className='mobilecardd'>
                                                <p className='nametext text-center'>{item.name}</p>
                                                <p className='texttextt text-center'>{item.text}</p>

                                            </div>

                                        </Col>
                                    ))}
                                </Row>


                            </div>
                        </div>

                        <div className='mobilediv'>
                            <div className='rowdiv'>
                                <Row>
                                    {data.map((item, index) => (
                                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} className='mb-4 mx-auto'>
                                            <div className='mobilecardd'>


                                                <div className='text-center mb-3'>
                                                    <img src={item.image} className="boxximg" />
                                                </div>
                                                <p className='nametext text-center'>{item.name}</p>
                                                <p className='texttextt text-center'>{item.text}</p>

                                            </div>

                                        </Col>
                                    ))}
                                </Row>

                            </div>
                        </div>
                    </div>
                </Container>
            </section>

        </>
    )
}

export default HowWeWork